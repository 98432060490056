import * as THREE from "three";
// import * as dat from "lil-gui";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";

/**
 * Debug
 */
// const gui = new dat.GUI();

// const parameters = {
//   materialColor: "#7653a2",
// };

// gui.addColor(parameters, "materialColor").onChange(() => {
//   material.color.set(parameters.materialColor);
// });

/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Objects
 * */

// Create a loader for the OBJ file
var loader = new OBJLoader();
var textureLoader = new THREE.TextureLoader();
let pear;

// Particles
const particlesCount = 750;
const positions = new Float32Array(particlesCount * 3);
const pointSizes = new Float32Array(particlesCount * 3);

for (let i = 0; i < particlesCount * 3; i++) {
  positions[i * 3 + 0] = (Math.random() - 0.5) * 3.5 + 0.2;
  positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
  positions[i * 3 + 2] = (Math.random() - 0.5) * 10;

  pointSizes[i] = Math.random() * 1 - 0.5;
}

const geometry = new THREE.BufferGeometry();
geometry.setAttribute(
  "position",
  new THREE.Float32BufferAttribute(positions, 3)
);
geometry.setAttribute("size", new THREE.Float32BufferAttribute(pointSizes, 1));
geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

// Material
const particlesMaterial = new THREE.PointsMaterial();

particlesMaterial.size = 0.04;
particlesMaterial.sizeAttenuation = true;

particlesMaterial.color = new THREE.Color("#fff7e6");

particlesMaterial.transparent = true;
particlesMaterial.alphaMap = textureLoader.load("textures/dust.png");
particlesMaterial.depthWrite = false;
particlesMaterial.blending = THREE.AdditiveBlending;

const particles = new THREE.Points(geometry, particlesMaterial);
scene.add(particles);

// Pear
// Load the OBJ file
loader.load(
  "obj/PrickleyPear.obj",
  function (object) {
    var normalMap = textureLoader.load("textures/nmap.jpg");
    var roughnessMap = textureLoader.load("textures/roughness.jpg");
    var specularMap = textureLoader.load("textures/spec.jpg");
    var texture = textureLoader.load("textures/texture.jpg");

    pear = object;
    pear.scale.set(20, 20, 20);
    pear.rotation.set(1, 0.5, 0.5);

    // Create material using the maps
    var material = new THREE.MeshPhysicalMaterial({
      normalMap: normalMap,
      roughnessMap: roughnessMap,
      specularMap: specularMap,
      map: texture,
      sheen: 1.0,
      wireframe: false,
    });
    pear.material = material;

    // Apply the material to the loaded object
    object.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = material;
      }
    });

    scene.add(pear);
  },
  function (xhr) {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  function (error) {
    console.log("An error occurred while loading the OBJ file");
  }
);

// Background
const width = Math.min(window.innerWidth, 1200);
const height = Math.min(window.innerHeight, 1200);
const size = Math.max(height, width);
const background = new THREE.Mesh(
  new THREE.PlaneGeometry((size * 2) / 100, size / 100),
  new THREE.MeshBasicMaterial({
    map: textureLoader.load("textures/kitchen.png"),
  })
);
background.position.z = -10;
scene.add(background);

// Lights
const sunLight = new THREE.DirectionalLight("#ffffff", 1.5);
sunLight.position.set(0, 1, -4);
sunLight.castShadow = true;
scene.add(sunLight);

const roofLight = new THREE.DirectionalLight("#ffffff", 0.4);
roofLight.position.set(0, 3, -4);
roofLight.castShadow = true;
scene.add(roofLight);

const floorLight = new THREE.DirectionalLight("#f8e4bb", 0.3);
floorLight.position.set(0, -2, -3);
floorLight.lookAt(0, 0, 0);
floorLight.castShadow = true;
scene.add(floorLight);

// const ambientLight = new THREE.AmbientLight("#ffffff", 0.2);
// scene.add(ambientLight);

const hemisphereLight = new THREE.HemisphereLight("#ffffff", "#752f13", 0.7);
scene.add(hemisphereLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.z = 6;
cameraGroup.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

document.addEventListener("keydown", (event) => {
  if (event.key === "x") {
    pear.rotation.x += 0.1;
    pear.material.wireframe = !pear.material.wireframe;
  }
});

// Scroll
document.addEventListener("wheel", (event) => {
  // if event.deltaY is positive, increase depthScale, else decrease depthScale
  if (event.deltaY > 0) {
    pear.scale.x += 0.1;
    pear.scale.y += 0.1;
    pear.scale.z += 0.1;
  } else {
    pear.scale.x -= 0.1;
    pear.scale.y -= 0.1;
    pear.scale.z -= 0.1;
  }

  material.uniforms.depthScale.value = depthScale;
});

// Cursor
const cursor = {
  x: 0,
  y: 0,
};
window.addEventListener("mousemove", (event) => {
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = event.clientY / sizes.height - 0.5;
});

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  // animate the pear
  if (pear) {
    pear.rotation.x -= deltaTime * 0.12;
    pear.rotation.y += deltaTime * 0.1;
    pear.rotation.z += deltaTime * 0.08;
  }

  //   animate camera
  const parallaxX = cursor.x;
  const parallaxY = -cursor.y;

  cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 0.1;
  cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 0.1;

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
